import React from 'react'
import styled from "@emotion/styled"

const Cardwrap = styled.ul`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 2.5vw;
@media (max-width: 599px) {
  grid-template-columns: 1fr 1fr;
}
a {
  color: rgba(0,0,0,0.75);
  display: block;
  text-decoration: none;
  border-bottom: 0 !important;
  transition: all 0.3s ease;
  img {
      transition: all 0.3s ease;
  }
  &:hover {
    color: red;
    img {
        opacity: .6;
    }
  }
}
li {
  margin-bottom: 5vh;
}
img {
  width: 17.5vw;
  height: 17.5vw;
  object-fit: cover;
  @media (max-width: 599px) {
    width: 40vw;
    height: 40vw;
  }
}
h4 {
  margin-bottom: 0;
  margin-top: 2vh;
  padding: 0;
  font-size: 1rem;
  width: 100%;
}
`

const Cardgrid = ({ children }) => {
    return (
        <Cardwrap>
            { children }
        </Cardwrap>
    )
}

export default Cardgrid