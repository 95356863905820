import React from 'react'

const Card = (props) => {
    const image = props.imagepath
    return (
        <li>
            <a href={props.link} target="+blank">
            <img src={image} alt="this" />
            <h4>{props.title}</h4>
            </a>
        </li>
    )
}

export default Card