import React from 'react'
import styled from "@emotion/styled"

const Column = styled.div`
float: left;
width: 27.5%;
@media (max-width: 599px) {
    width: 33.33%;
}
ul {
    padding: 0;
    width: 90%;
    li {
        line-height: 1.33rem;
        margin-bottom: 2.5vh;
        margin-right: 2vw;
    }
    a {
        text-transform: none;
        letter-spacing: 0;
    }    
}
`

const Sidebar = ({ children }) => {
    return (
        <Column>
        <ul>
            {children}
        </ul>
        </Column>
    )
}

export default Sidebar