exports.components = {
  "component---src-pages-about-board-mdx": () => import("./../../../src/pages/about/board.mdx" /* webpackChunkName: "component---src-pages-about-board-mdx" */),
  "component---src-pages-about-fellows-mdx": () => import("./../../../src/pages/about/fellows.mdx" /* webpackChunkName: "component---src-pages-about-fellows-mdx" */),
  "component---src-pages-about-founding-mdx": () => import("./../../../src/pages/about/founding.mdx" /* webpackChunkName: "component---src-pages-about-founding-mdx" */),
  "component---src-pages-about-mission-mdx": () => import("./../../../src/pages/about/mission.mdx" /* webpackChunkName: "component---src-pages-about-mission-mdx" */),
  "component---src-pages-about-participants-mdx": () => import("./../../../src/pages/about/participants.mdx" /* webpackChunkName: "component---src-pages-about-participants-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-participate-bewilder-mdx": () => import("./../../../src/pages/participate/bewilder.mdx" /* webpackChunkName: "component---src-pages-participate-bewilder-mdx" */),
  "component---src-pages-participate-contact-mdx": () => import("./../../../src/pages/participate/contact.mdx" /* webpackChunkName: "component---src-pages-participate-contact-mdx" */),
  "component---src-pages-participate-donate-mdx": () => import("./../../../src/pages/participate/donate.mdx" /* webpackChunkName: "component---src-pages-participate-donate-mdx" */),
  "component---src-pages-participate-rerewrite-mdx": () => import("./../../../src/pages/participate/rerewrite.mdx" /* webpackChunkName: "component---src-pages-participate-rerewrite-mdx" */),
  "component---src-pages-work-exhibitions-mdx": () => import("./../../../src/pages/work/exhibitions.mdx" /* webpackChunkName: "component---src-pages-work-exhibitions-mdx" */),
  "component---src-pages-work-indexdi-mdx": () => import("./../../../src/pages/work/indexdi.mdx" /* webpackChunkName: "component---src-pages-work-indexdi-mdx" */),
  "component---src-pages-work-publications-mdx": () => import("./../../../src/pages/work/publications.mdx" /* webpackChunkName: "component---src-pages-work-publications-mdx" */),
  "component---src-pages-work-residencies-10-joy-index-mdx": () => import("./../../../src/pages/work/residencies/10_joy/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-10-joy-index-mdx" */),
  "component---src-pages-work-residencies-10-marfa-index-mdx": () => import("./../../../src/pages/work/residencies/10_marfa/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-10-marfa-index-mdx" */),
  "component---src-pages-work-residencies-11-makedo-index-mdx": () => import("./../../../src/pages/work/residencies/11_makedo/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-11-makedo-index-mdx" */),
  "component---src-pages-work-residencies-11-montreal-index-mdx": () => import("./../../../src/pages/work/residencies/11_montreal/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-11-montreal-index-mdx" */),
  "component---src-pages-work-residencies-12-berlin-index-mdx": () => import("./../../../src/pages/work/residencies/12_berlin/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-12-berlin-index-mdx" */),
  "component---src-pages-work-residencies-12-fastforward-index-mdx": () => import("./../../../src/pages/work/residencies/12_fastforward/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-12-fastforward-index-mdx" */),
  "component---src-pages-work-residencies-17-otherwise-index-mdx": () => import("./../../../src/pages/work/residencies/17_otherwise/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-17-otherwise-index-mdx" */),
  "component---src-pages-work-residencies-17-wildness-index-mdx": () => import("./../../../src/pages/work/residencies/17_wildness/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-17-wildness-index-mdx" */),
  "component---src-pages-work-residencies-18-rewrite-index-mdx": () => import("./../../../src/pages/work/residencies/18_rewrite/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-18-rewrite-index-mdx" */),
  "component---src-pages-work-residencies-19-currency-index-mdx": () => import("./../../../src/pages/work/residencies/19_currency/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-19-currency-index-mdx" */),
  "component---src-pages-work-residencies-19-futurespective-index-mdx": () => import("./../../../src/pages/work/residencies/19_futurespective/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-19-futurespective-index-mdx" */),
  "component---src-pages-work-residencies-19-viewaskew-index-mdx": () => import("./../../../src/pages/work/residencies/19_viewaskew/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-19-viewaskew-index-mdx" */),
  "component---src-pages-work-residencies-21-prepostness-index-mdx": () => import("./../../../src/pages/work/residencies/21_prepostness/index.mdx" /* webpackChunkName: "component---src-pages-work-residencies-21-prepostness-index-mdx" */),
  "component---src-pages-work-residencies-mdx": () => import("./../../../src/pages/work/residencies.mdx" /* webpackChunkName: "component---src-pages-work-residencies-mdx" */),
  "component---src-pages-work-shop-mdx": () => import("./../../../src/pages/work/shop.mdx" /* webpackChunkName: "component---src-pages-work-shop-mdx" */),
  "component---src-pages-work-sitearchive-mdx": () => import("./../../../src/pages/work/sitearchive.mdx" /* webpackChunkName: "component---src-pages-work-sitearchive-mdx" */),
  "component---src-pages-xxx-index-js": () => import("./../../../src/pages/xxx-index.js" /* webpackChunkName: "component---src-pages-xxx-index-js" */)
}

