import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby-plugin-modal-routing-3'


const Listwrap = styled.ul`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 2.5vw;
@media (max-width: 599px) {
  grid-template-columns: 42.5vw 42.5vw;
  grid-gap: 5vw;
}
a {
  color: rgba(0,0,0,0.75);
  transition: all 0.3s ease;
  &:hover {
    color: red;
  }
}
li {
  margin-bottom: 5vh;
}
img {
  width: 17.5vw;
  height: 17.5vw;
  @media (max-width: 599px) {
    width: 42.5vw;
    height: 42.5vw;
  }
}
h4 {
  margin-bottom: 0;
  margin-top: 2vh;
  padding: 0;
  font-size: 1rem;
  width: 100%;
  font-family: courier;
  box-sizing: border-box;
  text-transform: uppercase;
  line-height: 1.25em;
}

`

const Description = styled.div`
padding-right: 0 !important;
width: 100% !important;
font-weight: 400;
line-height: 1.5em;
margin-top: 1vh;
text-transform: uppercase;
font-size: .875rem;
`

export default function Inquirylist(props) {
    const data = useStaticQuery(graphql`
    query {
        allMdx(
          filter: {frontmatter: {section: {eq: "Residencies"}}}
          sort: {order: DESC, fields: frontmatter___date}
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                short_desc
                location
                realdate
                ledeimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }      
              }
              slug
            }
          }
        }
      }   
    `)

    return (
        <Listwrap className={props.listClass}>
        {
        data.allMdx.edges.map(({node}) => (
          <li key={node.id}>
            <Link to={'/' + node.slug} asModal>
            {node.frontmatter.ledeimage && <GatsbyImage image={node.frontmatter.ledeimage.childImageSharp.gatsbyImageData} alt={node.frontmatter.title} objectFit="cover" />}
            <h4>{node.frontmatter.title}</h4>
            <Description>{node.frontmatter.location}<br />{node.frontmatter.realdate}</Description>
            {/* {node.frontmatter.short_desc} */}
            </Link>
          </li>
        ))
        }
       </Listwrap>
    )
}